import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Switch, Route, } from 'react-router-dom';

//import Navigation from './Component/Template/Navigation';

import Index from './views/Index'
import About from './views/About'
import Resume from './views/Resume';
import Login from './views/Login';



// All of our CSS
import './static/css/main.scss';






class App extends Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  
 

  render() {
    
      return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Index} />
                <Route path="/about" component={About} />
                <Route path="/resume" component={Resume} />
                <Route path="/login" component={Login} />

            </Switch>
        

        </BrowserRouter>

      );
     
    }

  }
export default App;