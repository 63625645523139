import React, { Component } from 'react';

import Helmet from 'react-helmet';



class Login extends Component {
  constructor(props) {
    super(props)
  }




  render(){
    return(
      <div id="login">
        <Helmet title="Login" />
        <h1>Login Page</h1> 
      </div>

    )

  }

}

export default Login;