import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import data from '../../data/contact';
import meImage from '../../images/PeterToddBW.jpg'


const Nav = () => (
  <section id="sidebar">
    <section id="intro">
    
      <Link to="/" className="logo">
        <img src={meImage} alt="Peter Todd" />
      </Link>
   
      <header>
        <h2><a href="mailto:peter@petertodd.com">Peter Todd</a></h2>
      </header>
    </section>

    

    <section id="footer">
      <ul className="icons">
        {data.map(s => (
          <li key={s.label}><a href={s.link}><FontAwesomeIcon icon={s.icon} /> {s.label} <FontAwesomeIcon icon="coffee" /> </a></li>
        ))}
      </ul>
      <p className="copyright">&copy;    Peter Todd </p>
    </section>
  </section>
);

export default Nav;
