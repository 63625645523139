import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import Main from '../layouts/Main';

import Education from '../Component/Resume/Education';
import Experience from '../Component/Resume/Experience';
import Skills from '../Component/Resume/Skills';
import Courses from '../Component/Resume/Courses';

import References from '../Component/Resume/References';

import courses from '../data/resume/courses';
import degrees from '../data/resume/degrees';
import positions from '../data/resume/positions';
import { skills, categories } from '../data/resume/skills';

const sections = [
  'Education',
  'Experience',
  'References',
];



class Resume extends Component {
    constructor(props) {
      super(props)
       }
    render(){
        return(
            <Main>
                <Helmet title="Resume" />
                <article className="post" id="resume">
                <header>
                    <div className="title">
                    <h2><Link to="resume">Resume</Link></h2>
                    <div className="link-container">
                        {sections.map(sec => (
                        <h4 key={sec}>
                            <a href={`#${sec.toLowerCase()}`}>{sec}</a>
                        </h4>))}
                    </div>

                    </div>
                </header>
                <Education data={degrees} />
                <Experience data={positions} />
                <References />

                </article>
            </Main>
        )
    }
}



export default Resume;
