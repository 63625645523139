
const positions = [
  {
    company: 'OpenCapacity',
    position: 'C.T.O.',
    link: 'http://opencapacity.co',
    daterange: 'March 2017 - Present',
    points: [
      'IoT | big data | machine learning | mobile', 
      'Designed and implemented the Cloud and IoT architecture (GCP & AWS) enabling OpenCapacity to deliver innovative high profile public facing projects in London and Stuttgart. Engaged with stakeholders in Germany and the UK to ensure efficient delivery within budget and timeframe'
    ],
  }, {
    company: 'Bright Blue Circle, UK',
    position: 'Founder CTO',
    daterange: 'January 2016 - February 2017',
    points: [
      'Bright Leased Asset Management (BLAM).  Synchronized data between local iOS app and remote cloud google datastore including images and calendar bookings. Subscription based model (Braintree) enabling team collaboration.  XCTest (unit and UI) and JUnit for app testing. ',
    ],
  }, {
    company: 'Babylon Health, London, UK',
    position: 'Lead iOS Developer ',
    link: 'https://www.babylonhealth.com',
    daterange: 'February 2016 - March 2016',
    points: [
      'The world\'s first mobile health service: consultations with top doctors, cutting-edge monitoring, and clinical records; all in your pocket.',
    ],
  }, {
    company: 'Proffer Global Limited, London, UK',
    position: 'Platform Architect and Lead iOS Developer',
    daterange: 'February 2014 - February 2015',
    points: [
      'Proffer was a startup mobile concierge service enabling businesses to connect with consumers (similar to Open Table and Uber)',
      'Lead iOS Developer and overall technical architect for a development team including android, web and Google App developers.',  
      'proffer (iPhone, November 2014) - the “what”, “where”, “when” search app.',
      'proffer provider (iPad, November 2014) - the supplier app, including scheduler, push notifications, supplier configuration, HR and CRM capability.',
    ],
  },{
    company: 'Bright Green Star Ltd, London, UK',
    position: 'Founder CTO',
    daterange: 'September 2012 - September 2015',
    points: [
      'LetsRunner (iPad, 2012-current) - a fully featured cost accounting app', 
      'Property Flyer (iPhone/iPad, 2013-14) - a property marketing tool.',
      'FitRoute (iPhone 2013-14) – record and share favorite exercise routes.',
      'Fun To Find (iPhone 2013-14) – record and share favorite places.',
    ],
  }, {
    company: 'PTPeople Ltd, London, UK.',
    position: 'Co-Founder CTO',
    daterange: 'February 2012 - December 2013',
    points: [
      'PTSession / PTPeople – Fitness apps for PT Trainers.',
    ],
  }, {
    company: 'CUPRIS Ltd, London, UK',
    position: 'Co-Founder CTO',
    link: 'https://www.cupris.com',
    daterange: 'December 2011 - March 2012',
    points: [
      'Cupris “The hospital in your pocket” was an iPhone and Rails based health venture.',
      'Won “Dragons Den” type investment from Design London.',
    ],
  },
  {
    company: 'SThree Recruitment, London.',
    position: 'Business Intelligence Manager',
    link: 'https://www.sthree.com/en/about-us',
    daterange: 'February 2008 - December 2011',
    points: [
      'Led 6 developers during a migration from legacy systems',
      'Transformed into an award winning team',
    ],
  },{
    company: 'PA Consulting, London',
    position: 'Principal Consultant',
    link: 'https://www.paconsulting.com',
    daterange: 'November 2005 - December 2007',
    points: [
      'Connecting for Heath (NHS) – Project Manager (2007).  Reviewed existing NPfIT organisational structure. ',
      'Aegate (Venture company) – Lead Oracle/Java Developer (2006/07). Reviewed the ER model, created java pre-processing tools to format input data (XML), designed and implemented a PL/SQL interface.',
      'Connecting for Health (NHS) – Senior Technical Consultant (2005/06).  Senior member of the recovery team brought in to ensure successfully delivery of a high profile national programme.',
      'Highways Agency – Solution Architect (2007).  Led the team creating an Oracle Spatial and ArcGIS solution.',
    ],
  },{
    company: 'Axcent/Tornado-IS Consulting. UK',
    position: 'Technical Manager',
    link: 'https://www.sthree.com/en/about-us',
    daterange: 'June 2000 - November 2005',
    points: [
      'Lead the ERP teams across several clients. ',
    ],
  }
];

export default positions;
