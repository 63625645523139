import {faGithub} from '@fortawesome/free-brands-svg-icons/faGithub';
import {faPinterest} from '@fortawesome/free-brands-svg-icons/faPinterest';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import {faAngellist} from '@fortawesome/free-brands-svg-icons/faAngellist';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons/faEnvelope';
import {faStackOverflow} from '@fortawesome/free-brands-svg-icons/faStackOverflow';
import {faMedium} from '@fortawesome/free-brands-svg-icons/faMedium';




const data = [
  {
    link: 'https://github.com/petetodd',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://stackoverflow.com/users/532206/greentor',
    label: 'Stackoverflow',
    icon: faStackOverflow,
  },
  {
    link: 'https://medium.com/me/stories/public',
    label: 'Medium',
    icon: faMedium,
  },
  {
    link: 'https://www.pinterest.co.uk/peter5621',
    label: 'pinterest',
    icon: faPinterest,
  },
  {
    link: 'https://www.linkedin.com/in/petertodd2008',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://angel.co/peter-todd-2',
    label: 'Angel List',
    icon: faAngellist,
  },
  {
    link: 'https://twitter.com/petetodd',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'mailto:peter@petertodd.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
