const degrees = [
  {
    school: 'Imperial Collage',
    degree: 'Executive MBA and Diploma of Imperial College ',
    link: 'https://www.imperial.ac.uk/business-school/programmes/executive-mba/',
    year: 2009,
  }, {
    school: 'Liverpool University',
    degree: 'MSc(Dist.) Computing and Information Management',
    link: 'https://www.ljmu.ac.uk/',
  },
  {
    school: 'University of Plymouth',
    degree: 'BSc(hons) Psychology',
    link: 'https://www.plymouth.ac.uk/',
  },
];

export default degrees;
