import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';


import Header from '../Component/Template/Header';
import Navigation from '../Component/Template/Navigation';






class Main extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <div id="wrapper">
        <Helmet titleTemplate="%s | Peter Todd" defaultTitle="Peter Todd" />
        <Header />
        <div id="main">
          {this.props.children}
        </div>
        {!this.props.fullPage && <Navigation />}
      </div>);
  }
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullPage: PropTypes.bool,
};

Main.defaultProps = {
  children: null,
  fullPage: false,
};

export default Main;
