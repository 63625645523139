import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
// import ReactMarkdown from 'react-markdown';
import Markdown from 'react-markdown';


import Main from '../layouts/Main';

import markdown_path from '../data/about.md';



class About extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      markdown: null,
      wordcount: 0,
     }
    
  }

  componentWillMount() {
    fetch(markdown_path).then((response) => response.text()).then((text) => {
      this.setState({ markdown: text })
      this.setState({wordcount: this.state.markdown.split(/\s+/)
        .map(s => s.replace(/\W/g, ''))
        .filter(s => s.length).length})
    })
  }


  render(){
    return(
      <Main>
        <Helmet title="About" />
        <article className="post" id="about">
          <header>
            <div className="title">
              <h2><Link to="/about">Trekking and Cultural buff </Link></h2>
            </div>
          </header>
        <Markdown
        source={this.state.markdown}
        escapeHtml={true}
        />
      </article>
  </Main>

    )

  }

}

export default About;
