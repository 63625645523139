const data = [
  {
    path: '/',
    label: 'Peter Todd',
    index: true,
  },
  {
    path: '/about',
    label: 'About',
  },
  {
    path: '/resume',
    label: 'Resume',
  },
];

export default data;
